
import { Component } from 'vue-property-decorator';
import VueBase from '@/components/VueBase.vue';
import { readUserProfile } from '@/store/main/getters';

@Component
export default class UserProfile extends VueBase {
  get userProfile() {
    return readUserProfile(this.$store);
  }

  public goToEdit() {
    this.$router.push(`${this.prefixSiteUrl}/main/profile/edit`);
  }

  public goToPassword() {
    this.$router.push(`${this.prefixSiteUrl}/main/profile/password`);
  }
}
